<template>
  <v-navigation-drawer app dark :value="$root.LeftNav" class="primary">
    <v-list dense nav class="py-0 mt-3">
      <v-list-item two-line class="px-0">
        <v-list-item-avatar>
          <img v-if="vg_user.avatar" :src="vg_user.avatar" alt="John" /> 
          <v-icon v-else>fas fa-user</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Welcome {{vg_user.display_name}}</v-list-item-title>
          <v-list-item-subtitle style="cursor:pointer">
            <router-link class="profile" to="/profile">Update Profile</router-link>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        active-class
        v-for="item in navigation"
        :key="item.title"
        @click="changeNav(item)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      navigation: [],
      current_nav: {}
    };
  },
  mounted() {
    const dis = this;
    this.$router.options.routes.forEach(route => {
      if (route.meta.nav.addToNav === true) {
        dis.navigation.push({
          title: route.meta.nav.navText,
          path: route.path,
          icon: route.meta.nav.navIcon,
          name: route.name
        });
      }
      // Check if we have childrens
      if (route.children) {
        route.children.forEach(function (childRoute) {
          let findIndex = dis.vg_groupRole.role.findIndex(each => each.model==childRoute.meta.nav.navText)
          if (childRoute.meta.nav.addToNav === true && (findIndex>=0 || childRoute.meta.nav.navText=='Dashboard')) {
            dis.navigation.push({
              title: childRoute.meta.nav.navText,
              path: childRoute.path,
              icon: childRoute.meta.nav.navIcon,
              name: childRoute.name
            });
          }
        });
      }
    });
  },
  computed: {
    ...mapGetters({
      vg_user: "login/user",
      vg_groupRole: "login/role"
    })
  },
  methods: {
    changeNav(item) {
      this.current_nav = item;
      if (this.$router.history.current.name != item.name)
        this.$router.push({ name: item.name });
    }
  }
};
</script>

<style scoped>
.profile {
  color: #ccc;
  text-decoration: none;
}
</style>