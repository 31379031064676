module.exports = {
    state: 'production',
    development: {
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        file_path: process.env.VUE_APP_FILE_PATH,
        my_folder: process.env.VUE_APP_FILE_MY_FOLDER,
        micro_url: process.env.VUE_APP_MICRO_URL,
        api_token: process.env.VUE_APP_API_TOKEN,
    },
    test: {
        api_token: process.env.VUE_APP_API_TOKEN,
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        my_folder: process.env.VUE_APP_FILE_MY_FOLDER,
        file_path: process.env.VUE_APP_FILE_PATH,
        micro_url: process.env.VUE_APP_MICRO_URL
    },
    production: {
        api_token: process.env.VUE_APP_API_TOKEN,
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        my_folder: process.env.VUE_APP_FILE_MY_FOLDER,
        file_path: process.env.VUE_APP_FILE_PATH,
        micro_url: process.env.VUE_APP_MICRO_URL
    }
}