import PostApi from '@/api/post'

export default {
    namespaced: true,
    state: {
        list: [],
        listTrash: [],
        editObj: {}
    },
    getters: {
        list: state => state.list,
        listTrash: state => state.listTrash,
        editObj: state => state.editObj,
    },
    mutations: {
        list(state, data) {
            state.list = data
        },
        listTrash(state, data){
            state.listTrash =  data
        },
        editObj(state, data) {
            state.editObj = data
        },
        reset(state) {
            state.list = []
            state.listTrash = []
            state.editObj = {}
        }
    },
    actions: {
        async list({ commit }) {
            try{
                let {data} = await PostApi.list();
                commit('list', data.payload);
            }catch(err){
                commit('list', [])
            }
        },
        async trashApp({commit}){
            try{
                let {data} = await PostApi.trash();
                commit('listTrash', data.payload);
            }catch(err){
                commit('listTrash', [])
            }
        }
    }
};
