<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Edit Article</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="10" offset-md="1">
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="form.status"
                        :items="status"
                        :rules="rules.status"
                        label="Status*"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-switch
                        v-model="form.main"
                        :label="`Featured: ${(form.main)?form.main.toString():'false'}`"
                      ></v-switch>
                    </v-col>
                    <v-col>
                       <v-text-field
                        class="mt-2"
                        v-model="form.sort"
                        hint="Please enter number"
                        label="Sort"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                        class="mt-2"
                        v-model.trim="form.organism"
                        hint="Please enter organism name"
                        label="Organism"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        class="mt-2"
                        v-model="form.name"
                        hint="Please enter name"
                        label="Name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  
                  <v-text-field
                    class="mt-2"
                    v-model="form.title"
                    :rules="rules.title"
                    hint="Please enter title"
                    label="Title*"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="form.slug"
                    :rules="rules.slug"
                    hint="Please enter slug"
                    label="Slug*"
                  ></v-text-field>

                  <v-row>
                    <v-col>
                      <group-checkbox
                        :items="categories"
                        v-model="form.category_ids"
                        :rules="rules.category_ids"
                        label="Category*"
                        />
                    </v-col>
                    
                    <v-col>
                      <file-manager
                        v-model="form.image"
                        width="200"
                        height="200"
                        :allowed="['png', 'jpg', 'jpeg']"
                        :multiple="false"
                        :crop="false"
                        :rules="rules.image"
                        label="Image* (250x150)"
                      />
                    </v-col>
                  </v-row>

                  <v-textarea
                    outlined
                    v-model="form.short_description"
                    :rules="rules.short_description"
                    label="Short Description*"
                  ></v-textarea>
                  <v-progress-linear
                    rounded
                    :background-color="getProgress('short', 'color')+' lighten-3'"
                    :color="getProgress('short', 'color')"
                    :value="getProgress('short')"
                    style="position:relative; top:-30px; margin-left:5px; margin-right:5px; width:98%"
                  ></v-progress-linear>

                  <tag v-model="form.tags" label="Tag"  />

                  <editor 
                    class="main-editor pb-9" 
                    v-model="form.description" 
                    label="Description" 
                    :rules="rules.description" 
                    :loading="loadingEditor"
                  />

                  <v-textarea
                    outlined
                    label="Reference"
                    v-model="form.reference"
                  ></v-textarea>

                  <v-textarea
                    outlined
                    label="Meta Content"
                    v-model="form.meta_content"
                  ></v-textarea>

                  <v-progress-linear
                    rounded
                    :background-color="getProgress('meta', 'color')+' lighten-3'"
                    :color="getProgress('meta', 'color')"
                    :value="getProgress('meta')"
                    style="position:relative; top:-30px; margin-left:5px; margin-right:5px; width:98%"
                  ></v-progress-linear>

                  <v-card-actions>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="finish" :disabled="!valid">Finish</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Editor from '@/components/editor'
import ArticleApi from '@/api/article'
import {mapGetters, mapMutations} from 'vuex'
import fileManager from '@/components/file-manager'
import GroupCheckbox from '@/components/GroupCheckbox'
import Tag from '@/components/Tag'
import CategoryAPI from '@/api/category'

export default {
  components: {
    Editor,
    fileManager,
    GroupCheckbox,
    Tag
  },
  data() {
    return {
      valid: false,
      status: [
        {text: 'Assigned', value: 'assigned'}, 
        {text: 'Progress', value: 'progress'}, 
        {text: 'Completed', value: 'completed'}, 
        {text: 'Published', value: 'published'}
      ],
      categories: [],
      form: {
        status: "",
        name: "",
        organism: "",
        title: "",
        slug: "",
        category_ids: [],
        short_description: "",
        tags: [],
        image: "",
        description: "",
        meta_content: "",
        reference: ""
      },
      rules: {
        status: [v => !!v || "Status is required"],
        title: [v => !!v || "Title is required"],
        slug: [v => !!v || "Slug is required"],
        category_ids: [v => !!v || "Category is required"],
        short_description: [v => !!v || "Short description is required"],
        image: [v => !!v || "Image is required"],
        description: [v => !!v || "Description is required"]
      },
      loadingEditor: true
    };
  },
  async mounted(){
      this.spinner(true)
      await this.getArticle()

      let {data} = await CategoryAPI.listIdAndName()
      this.categories = data.payload
      this.loadingEditor = false
      this.spinner(false)
  },
  watch: {
    'form.title'(value){
      this.form.slug = value.trim().replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "-").replace(/-{2,}/g, '-').toLowerCase()
    }
  },
  computed: {
      ...mapGetters({
          vg_editObj: 'article/editObj'
      })
  },
  methods: {
    async getArticle(){
      try{
        let {data} = await ArticleApi.findBySlug(this.$route.params.slug)
        if(data.payload){
          this.vm_editObj(data.payload)

          this.form.status = this.vg_editObj.status
          this.form.name = this.vg_editObj.name
          this.form.organism = this.vg_editObj.organism
          this.form.title = this.vg_editObj.title
          this.form.slug = this.vg_editObj.slug
          this.form.main = this.vg_editObj.main
          this.form.category_ids = this.getCategoryIds(this.vg_editObj.category)
          this.form.short_description = this.vg_editObj.short_description
          this.form.reference = this.vg_editObj.reference
          this.form.tags = this.vg_editObj.tag
          this.form.image = this.vg_editObj.image
          this.form.description = this.vg_editObj.description
          this.form.sort = this.vg_editObj.sort
          this.form.meta_content = this.vg_editObj.meta_content
        }
      }catch(err){
        console.log(err)
      }
    },
    getProgress(field, type=''){
      // get field
      let minAllowed, maxAllowed, value
      if(field=='short'){
        minAllowed = 180
        maxAllowed = 240
        value = this.form.short_description.length
      }else{
        minAllowed = 155
        maxAllowed = 250
        value = this.form.meta_content.length
      }

      // get color or value
      if(type=='color'){
        if(value<=minAllowed){
          return 'grey'
        }else if(value<=maxAllowed){
          return 'green'
        }else{
          return 'red'
        }
      }else{
        return parseInt(value/maxAllowed*100)
      }
    },
    getCategoryIds(categories){
      let ids = []
      categories.forEach(category => {
        ids.push(category.id)
      })
      return ids
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await ArticleApi.update(this.vg_editObj.id, this.form)
          this.snackbar({status: true, message: data.message, color: 'success'})
          this.spinner(false)
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color: 'error'})
          this.spinner(false)
        }
      }
    },
    finish(){
      this.save()
      this.$router.push({name: 'Article'})
    },
    cancel() {
      this.$router.push({ name: "Article" });
    },
    ...mapMutations({
      vm_editObj: 'article/editObj'
    })
  }
};
</script>
<style>
.main-editor h2{
    font-size: 24px;
    color: #29648A;
}
.main-editor h3{
  font-size: 21px;
  color: #2E9CCA;
}
.main-editor h4{
  font-size: 18;
  color: #29648A;
}
.main-editor p{
    margin-bottom:8px;
}
.main-editor figure figcaption{
  color: #bbb;
}
.main-editor figure img{
  height:250px;
  width:auto;
  max-width: 450px;
  max-height:450px;
}
.main-editor table{
  border-collapse: collapse;
}
.main-editor table th{
  background: #2e9cca;
  color: #fff;
}
.main-editor table tr:nth-child(odd) {
  width:100%;
  background: #E3F2FD;
}
.main-editor table tr th,
.main-editor table tr td{
  border:none;
  padding:3px 10px;
  text-align: left;
}
.editor-content>div>p,
.editor-content>div>h1,
.editor-content>div>h2,
.editor-content>div>h3,
.editor-content>div>h4,
.editor-content>div>table,
.editor-content>div>figure,
.editor-content>div>ul,
.editor-content>div>ol,
.editor-content>div>iframe,
.editor-content>div>blockquote{
  border: dotted thin #ccc;
  margin-bottom:2px !important;
}
</style>