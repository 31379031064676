<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Category</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">

                  <v-text-field
                    class="mt-2"
                    v-model.trim="category.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model.trim="category.slug"
                    :rules="rules.slug"
                    hint="Please enter slug"
                    label="Slug*"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="category.sort"
                    hint="Please enter sort"
                    label="Sort"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Category from '@/api/category'
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      category: {
        name: "",
        slug: "",
        sort: null
      },
      rules: {
        name: [v => !!v || "Name is required"],
        slug: [v => !!v || "Slug is required"]
      }
    };
  },
  watch: {
    'category.name'(value){
      this.category.slug = value.trim().replaceAll(" ", "-").toLowerCase()
    }
  },
  computed:{
    ...mapGetters({
      vg_user: 'login/user'
    })
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Category.create({...this.category, created_by: this.vg_user.id})
          this.snackbar({status: true, message: data.message, color:'success'})
          this.spinner(false)
          this.$router.push({name: 'Category'})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:'error'})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Category" });
    }
  }
};
</script>