import ReferenceApi from '@/api/reference'

export default {
    namespaced: true,
    state: {
        list: {},
        listTrash: [],
        addPop: false,
        editPop: false,
        editObj: {},
        query: {
            search: '',
            itemsPerPage: 15
        },
        form: {
            identifier_type: "",
            identifier: "",
            type: "",
            title: "",
            authors: "",
            journal: "",
            volume: "",
            issue: "",
            edition: "",
            editors: "",
            city: "",
            publisher: "",
            start_page: "",
            end_page: "",
            year: "",
            month: "",
            day: "",
            abstract: "",
            url: ""
        }
    },
    getters: {
        list: state => state.list,
        listTrash: state => state.listTrash,
        addPop: state => state.addPop,
        editPop: state => state.editPop,
        editObj: state => state.editObj,
        query: state => state.query,
        form: state =>  state.form
    },
    mutations: {
        list(state, data) {
            state.list = data
        },
        listTrash(state, data){
            state.listTrash =  data
        },
        addPop(state, data) {
            state.addPop = data
            // If add is on edit is false
            state.editPop = false
        },
        editPop(state, data) {
            state.editPop = data
            // If Edit is on add is false
            state.addPop = false
        },
        editObj(state, data) {
            state.editObj = data
        },
        query(state, data) {
            state.query = data
        },
        form(state, data){
            state.form = data
        },
        clear(state){
            state.form = {
                identifier_type: "",
                identifier: "",
                type: "",
                title: "",
                authors: "",
                journal: "",
                volume: "",
                issue: "",
                edition: "",
                editors: "",
                city: "",
                publisher: "",
                start_page: "",
                end_page: "",
                year: "",
                month: "",
                day: "",
                abstract: "",
                url: ""
            }
        },
        reset(state) {
            state.list = []
            state.listTrash = []
            state.addPop = false
            state.editPop = false
            state.editObj = {},
            state.query = {
                search: '',
                itemsPerPage: 15
            },
            state.form = {
                identifier_type: "",
                identifier: "",
                type: "",
                title: "",
                authors: "",
                journal: "",
                volume: "",
                issue: "",
                edition: "",
                editors: "",
                city: "",
                publisher: "",
                start_page: "",
                end_page: "",
                year: "",
                month: "",
                day: "",
                abstract: "",
                url: ""
            }
        }
    },
    actions: {
        async references({ commit }, queryString) {
            try{
                let {data} = await ReferenceApi.list(queryString);
                commit("list", data.payload);
            }catch(err){
                commit("list", {count:0, rows: []})
            }
        },
        async trashReferences({commit}){
            try{
                let {data} = await ReferenceApi.trash();
                commit("listTrash", data.payload);
            }catch(err){
                commit("listTrash", [])
            }
        }
    }
};