import {Node, mergeAttributes} from '@tiptap/core'

export default Node.create({
    name: 'bulletList',

    defaultOptions: {
        HTMLAttributes: {}
    },

    group: 'block list',

    content: 'listItem+',

    parseHTML(){
        return [
            {
                tag: 'ul'
            }
        ]
    },

    renderHTML({HTMLAttributes}){
        return ['ul', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands(){
        return {
            toggleBulletList: () => ({commands}) => {
                return commands.toggleList('bulletList', 'listItem')
            }
        }
    },

    addKeyboardShortcuts(){
        return {
            'Mod-Shift-8': () => this.editor.commands.toggleBulletList()
        }
    }
})