<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Article</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn v-if="role.add" class="mt-4" color="white black--text" rounded @click.prevent="createApi"
            >Add</v-btn
          >
          <v-btn v-if="role.trashes"
            class="ml-4 mt-4"
            color="red darken-4 grey--text text--lighten-2 font-weight-bold"
            rounded
            @click="trashedApi"
            >Trash</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_articles.rows"
          :loading="loading"
          :server-items-length="vg_articles.count"
          :options.sync="options"
          :search.sync="options.search"
          :items-per-page="options.itemsPerPage"
        > 
          <template v-slot:item.image="{ item }">
              <v-img :src="item.image" max-width="60" max-height="50" contain />
          </template>
          <template v-slot:item.organism="{ item }">
              <div :class="item.main?'font-weight-bold':''">{{item.organism}}</div>
              <div>{{item.name}}</div>
          </template>
          <template v-slot:item.sort="{ item }">
              <div class="text-center">#{{item.id}}</div>
              <div class="text-center">{{item.sort}}</div>
          </template>
          <template v-slot:item.category="{ item }">
              <v-chip x-small v-for="(cat, ind) in item.category" :key="ind">{{cat.name}}</v-chip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip x-small :color="color[item.status]" dark>{{
              item.status
            }}</v-chip>
          </template>
          <template v-slot:item.published_at="{ item }">
              <span v-if="item.published_at">{{new Date(item.published_at).toLocaleString('en-ZA')}}</span>
          </template>
          <template v-slot:item.updated_at="{ item }">
              <span v-if="item.updated_at">{{new Date(item.updated_at).toLocaleString('en-ZA')}}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="editItemInNewWindow(item)"
              >mdi-application-edit</v-icon
            >
            <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="role.delete" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapMutations, mapActions } from "vuex";
import ArticleApi from '@/api/article'
import VuetifyTable from '@/helper/table'
export default {
  data() {
    return {
      loading: true,
      headers: [
        { text: "#Id Sort", value: "sort", width:60},
        {text: "Image", value: "image"},
        { text: "Organism - Name", value: "organism"},
        { text: "Category", value: "category", sortable: false },
        { text: "Status", value: "status" },
        { text: "Published At", value: "published_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "action", sortable: false, width:130 }
      ],
      color: {
        assigned: "#BDBDBD",
        progress: "#EF5350",
        completed: "#66BB6A",
        published: "#29B6F6"
      },
      search: ''
    };
  },
  mounted(){
    this.spinner(false)
  },
  watch: {
    vg_queryObject: {
      handler: _.debounce(async function() {
        try{
          this.loading = true;
          await this.va_articles(this.optionsToQueryString(this.vg_queryObject));
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
      }, 900),
      deep: true
    },
    // reset page number on search
    'vg_queryObject.search'(){
      this.options.page = 1
    }
  },
  computed: {
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Article")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_articles: "article/list",
      vg_queryObject: "article/query"
    })
  },
  methods: {
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    createApi() {
      this.$router.push({ name: "ArticleCreate" })
    },
    editItemInNewWindow(item){
      let route = this.$router.resolve({name: 'ArticleEdit', params: {slug: item.slug}})
      window.open(route.href, '_blank')
    },
    editItem(item) {
      this.$router.push({ name: "ArticleEdit", params: {slug: item.slug} })
    },
    trashedApi() {
      this.$router.push({ name: "ArticleTrash" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm('Are you sure to delete this?').then(async () => {
        try{
          self.spinner(true)
          let {data} = await ArticleApi.delete(item.id)
          this.snackbar({status: true, message: data.message, color: 'success'})
          await this.va_articles(this.optionsToQueryString(this.vg_queryObject))
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "article/editObj",
      vm_queryObject: "article/query"
    }),
    ...mapActions({
      va_articles: "article/articles"
    })
  }
};
</script>