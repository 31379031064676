import {Node, mergeAttributes} from '@tiptap/core'

export default Node.create({
    name: 'listItem',

    defaultOptions: {
        HTMLAttributes:{}
    },

    content: 'block*',

    parseHTML(){
        return [
            {
                tag: 'li'
            }
        ]
    },

    renderHTML({HTMLAttributes}){
        return ['li', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addKeyboardShortcuts(){
        return {
            'Enter': () => this.editor.commands.splitListItem('listItem'), 
            'Tab': () => this.editor.commands.sinkListItem('listItem'),
            'Shift-Tab': () => this.editor.commands.liftListItem('listItem')
        }
    }
})