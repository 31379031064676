<template>
  <v-app>
    <v-snackbar :top="true" v-model="$root.Snackbar.status" :color="$root.Snackbar.color">
      {{$root.Snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar(false)">Close</v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="$root.Spinner" style="z-index: 9999;">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="$root.Confirm.status" persistent max-width="290">
      <v-card>
        <v-card-title class="secondary grey--text text--lighten-5">Confirmation</v-card-title>
        <v-card-text class="pt-3">{{$root.Confirm.message}}</v-card-text>
        <v-card-actions class="confirmation">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click.prevent="confirmationResponse('cancel')">Cancel</v-btn>
          <v-btn color="green darken-1" text @click.prevent="confirmationResponse('ok')">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <router-view />
  </v-app>
</template>

<script>
import { EmitBus } from '@/emit-bus'
import store from '@/store'
export default {
  name: 'App',
  created() {
     EmitBus.$on('unauthorized', (error) => {
       if(error.data && error.data.message){
          this.snackbar({status: true, message: error.data.message})
          store.commit('login/resetLogin')
       }
     })

     EmitBus.$on('forbidden', (error) => {
       if(error.data && error.data.message){
          this.snackbar({status: true, message: error.data.message})
          store.commit('login/resetLogin')
       }
     })
  },
  mounted(){
    //reset store : [clear application > clear site data] > hard refresh with code uncomment
    // for(let key in store.state){
    //   delete(store.state[key])
    // }
    // store.getters = {}
  },
  methods: {
    confirmationResponse(val){
      this.$root.Confirm.response = val
      this.$root.Confirm.status = false
    }
  }
};
</script>
