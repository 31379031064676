import {Node, mergeAttributes} from '@tiptap/core'

export default Node.create({
    name: 'iframe',

    defaultOptions: {
        HTMLAttributes: {
            width: "640",
            height:"480",
            frameborder:"0",
            allowfullscreen:true
        }
    },

    group: 'block',

    content: 'block*',

    draggable: true,

    parseHTML(){
        return [
            {
                tag: 'iframe'
            }
        ]
    },

    renderHTML({HTMLAttributes}){
        return ['iframe', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addAttributes(){
        return {
            src: {
                default: null
            }
        }
    },

    addCommands(){
        return {
            setIframe: attrs => ({commands}) => {
                commands.wrapIn('iframe', attrs)
            }
        }
    }
})