import axios from 'axios'
import config from './config.js'

import { EmitBus } from '@/emit-bus'

export const fileApi = axios.create({
    baseURL: `${config.file_url}/api`,
    headers: {
        'api_token': config.apiToken
    }
});

fileApi.interceptors.response.use(function (response) {
    return response
  }, function(error) {
      if(error.response.status===401){
        EmitBus.$emit("unauthorized", error.response)
      }else if(error.response.status===403){
        EmitBus.$emit("forbidden", error.response)
      }
    return Promise.reject(error)
  })

    export default ({
        all(folder) {
            return fileApi({
                method: 'get',
                url: `/folder/all${folder?'?folder='+folder:''}`,
                headers: {
                    'auth_token': config.auth_token
                }
            })
        },
        tree(folder) {
            return fileApi({
                method: 'get',
                url: `/folder/tree${folder?'?folder='+folder:''}`,
                headers: {
                    'auth_token': config.auth_token
                }
            })
        },
        list(option) {
            let url = `/file?${option}`
            return fileApi({
                method: 'get',
                url: url,
                headers: {
                    'auth_token': config.auth_token
                }
            })
        },
        upload(data){
            return fileApi({
                method: 'post',
                url: '/file',
                data: data,
                headers: {
                    'auth_token': config.auth_token
                }
            }) 
        },
        update(data){
            return fileApi({
                method: 'patch',
                url: '/file',
                data: data,
                headers: {
                    'auth_token': config.auth_token
                }
            }) 
        },
        delete(path) {
            return fileApi({
                method: 'delete',
                url: `/file`,
                data: path,
                headers: {
                    'auth_token': config.auth_token
                }
            })
        }
    })