import {Node, mergeAttributes} from '@tiptap/core'

export default Node.create({
    name: 'reference',

    defaultOptions: {
        HTMLAttributes: {
        }
    },

    group: 'inline',

    inline: true,

    content: 'inline*',

    draggable: true,

    parseHTML(){
        return [
            {
                tag: 'a[data-type="ref"]',
            }
        ]
    },

    renderHTML({HTMLAttributes}){
        return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {'data-type': 'ref'}), ['sup', '[R]']]
    },

    addAttributes(){
        return {
            href: {
                default: null
            }
        }
    },

    addCommands(){
        return {
            setReference: attributes => ({commands}) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: attributes
                })
            }
        }
    },

    addNodeView(){
        return ({
            HTMLAttributes
        }) => {
            const reference = document.createElement('a')
            const supScript = document.createElement('sup')
            
            reference.href = HTMLAttributes.href
            supScript.innerHTML = '[R]'
            reference.append(supScript)
        
            return {
                dom: reference
            }
        }
    }
})