import Reference from '@/views/reference';
import ReferenceCreate from '@/views/reference/create'
import ReferenceEdit from '@/views/reference/edit'
import ReferenceTrash from '@/views/reference/trash'

export default [
    {
        name: 'Reference',
        path: '/reference',
        component: Reference,
        meta: {
            nav: {
                addToNav: true,
                navText: 'Reference',
                header: 'Reference',
                navIcon: 'mdi-note-search',
            }
        }
    },
    {
        name: 'ReferenceCreate',
        path: '/reference/create',
        component: ReferenceCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Reference > Create',
                navIcon: '',
            }
        }
    },
    {
        name: 'ReferenceEdit',
        path: '/reference/edit',
        component: ReferenceEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Reference > Edit',
                navIcon: '',
            }
        }
    },
    {
        name: 'ReferenceTrash',
        path: '/reference/trash',
        component: ReferenceTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Reference > Trash',
                navIcon: '',
            }
        }
    }
]