<template>
    <div class="v-input">
        <div class="v-input__control">
            <label class="v-label v-label--active theme--light mb-2" v-text="label"></label>
            <div class="group-checkbox">
                <v-checkbox class="mt-0" v-model="selected" :label="each.name" :value="each.id" v-for="(each, index) in items" :key="index" hide-details></v-checkbox>
                <v-text-field class="d-none" v-model="selected.length" :rules="rules" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value', 'items', 'label', 'rules'],
    data(){
        return {
            selected: []
        }
    },
    watch: {
        value(values){
            this.selected = values
        },
        selected(values){
            this.$emit('input', values)
        }
    },
    mounted(){
        this.selected = this.value
    }
}
</script>
<style scoped>
    .group-checkbox{
        border: solid thin #aaa;
        max-height: 185px;
        overflow: auto;
    }
</style>
