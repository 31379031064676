<template>
    <v-dialog v-model="openRef" persistent max-width="450">
        <v-card>
            <v-toolbar elevation="0" dark class="secondary">
                <v-toolbar-title>Add Reference</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeLink">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <v-form ref="form" v-model="valid" @submit.prevent="addRef">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete :rules="refRule" v-model="form.id" :items="references" :loading="searchingReference" :search-input.sync="search" item-text="title" item-value="id" label="URL" placeholder="Type title" auto-select-first no-data-text></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn :disabled="!valid" type="submit" class="primary" block>Insert Reference</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import ReferenceAPI from '@/api/reference'

export default {
    props: ['openRef', 'refAttrs'],
    data(){
        return {
            refRule: [
                v => !!v || 'Required'
            ],
            valid: false,
            form: {
                id: ''
            },
            search: '',
            references: [],
            searchingReference: false
        }
    },
    watch: {
        openRef(val){
            if(val){                
                this.form.id = this.refAttrs.href
                if(this.form.id){
                    this.searchReference(this.form.id.split('#').pop())
                }
            }
        },
        search(word){
            this.searchReference(word)
        }
    },
    methods: {
        closeLink(){
            this.$emit('closeRef')
        },
        addRef(){
            if(this.$refs.form.validate()){
                this.$emit('addRef', this.form)
            }
        },
        async searchReference(word){
            if(word){
                this.searchingReference = true
                try{
                    let {data} = await ReferenceAPI.listIdAndTitle(word)
                    this.references = data.payload
                    this.searchingReference = false
                }catch(err){
                    this.searchingReference = false
                }
            }
        }
    }
}
</script>
