import { microApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    list() {
        return microApi({
            method: 'get',
            url: `/category`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    listIdAndName(){
        return microApi({
            method: 'get',
            url: `/category`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    create(data) {
        return microApi({
            method: 'post',
            url: `/category`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    update(id, data) {
        return microApi({
            method: 'patch',
            url: `/category/${id}`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    delete(id) {
        return microApi({
            method: 'delete',
            url: `/category/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    trash(){
        return microApi({
            method: 'get',
            url: `/category/trashes`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    restore(id){
        return microApi({
            method: 'put',
            url: `/category/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})