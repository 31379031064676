import ArticleApi from '@/api/article'

export default {
    namespaced: true,
    state: {
        list: {},
        listTrash: [],
        addPop: false,
        editPop: false,
        editObj: {},
        query: {
            search: '',
            itemsPerPage: 15
        },
        form: {
            status: "",
            name: "",
            title: "",
            slug: "",
            main: false,
            category_ids: [],
            short_description: "",
            tags: [],
            image: "",
            description: "",
            sort: 1,
            meta_content: ""
        }
    },
    getters: {
        list: state => state.list,
        listTrash: state => state.listTrash,
        addPop: state => state.addPop,
        editPop: state => state.editPop,
        editObj: state => state.editObj,
        query: state => state.query,
        form: state => state.form
    },
    mutations: {
        list(state, data) {
            state.list = data
        },
        listTrash(state, data){
            state.listTrash =  data
        },
        addPop(state, data) {
            state.addPop = data
            // If add is on edit is false
            state.editPop = false
        },
        editPop(state, data) {
            state.editPop = data
            // If Edit is on add is false
            state.addPop = false
        },
        editObj(state, data) {
            state.editObj = data
        },
        query(state, data) {
            state.query = data
        },
        form(state, data) {
            state.form = data
        },
        clear(state){
            state.form = {
                status: "",
                name: "",
                title: "",
                slug: "",
                main: false,
                category_ids: [],
                short_description: "",
                tags: [],
                image: "",
                description: "",
                sort: 1,
                meta_content: ""
            }
        },
        reset(state) {
            state.list = []
            state.listTrash = []
            state.addPop = false
            state.editPop = false
            state.editObj = {},
            state.query = {
                search: '',
                itemsPerPage: 15
            },
            state.form = {
                status: "",
                name: "",
                title: "",
                slug: "",
                main: false,
                category_ids: [],
                short_description: "",
                tags: [],
                image: "",
                description: "",
                sort: 1,
                meta_content: ""
            }
        }
    },
    actions: {
        async articles({ commit }, queryString) {
            try{
                let {data} = await ArticleApi.list(queryString);
                commit("list", data.payload);
            }catch(err){
                commit("list", {count:0, rows: []})
            }
        },
        async trashArticles({commit}){
            try{
                let {data} = await ArticleApi.trash();
                commit("listTrash", data.payload);
            }catch(err){
                commit("listTrash", [])
            }
        }
    }
};