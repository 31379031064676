import Post from "@/views/post";
import PostCreate from "@/views/post/create"
import PostEdit from "@/views/post/edit"
import PostTrash from "@/views/post/trash"

export default [
    {
        name: "Post",
        path: "/post",
        component: Post,
        meta: {
            nav: {
                addToNav: true,
                navText: "Post",
                header: "Post",
                navIcon: "mdi-note",
            }
        },
    },
    {
        name: "PostCreate",
        path: "/post/create",
        component: PostCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Post > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "PostEdit",
        path: "/post/edit/:slug",
        component: PostEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Post > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "PostTrash",
        path: "/post/trash",
        component: PostTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Post > Trash",
                navIcon: "",
            }
        }
    }
]
