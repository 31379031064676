import {Mark, mergeAttributes, markPasteRule} from '@tiptap/core'
import {Plugin, PluginKey} from 'prosemirror-state'

export const pasteRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)/gi

export const pasteRegexExact = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)$/gi

export default Mark.create({
    name: 'link',

    defaultOptions: {
        openOnClick: true,
        linkOnPaste: true,
        HTMLAttributes: {}
    },

    addAttributes() {
        return {
          href: {
            default: null,
          },
          target: {
            default: this.options.HTMLAttributes.target,
          },
          rel: {
              default: this.options.HTMLAttributes.rel
          }
        }
    },

    parseHTML(){
        return [
            {
                tag: 'a',
                getAttrs: el => el.getAttribute('data-type') !== 'ref'

            }
        ]
    },

    renderHTML({HTMLAttributes}){
        return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands(){
        return {
            setLink: attributes => ({commands}) => {
                commands.setMark('link', attributes)
            },
            toggleLink: attributes => ({commands}) => {
                commands.toggleMark('link', attributes)
            },
            unsetLink: attributes => ({commands}) => {
                commands.unsetMark('link', attributes)
            }
        }
    },

    addPasteRules() {
        return [
            markPasteRule(pasteRegex, this.type, match => ({ href: match[0] }))
        ]
    },

    addProseMirrorPlugins(){
        const plugins = []

        if(this.options.openOnClick){
            plugins.push(
                new Plugin({
                    key: new PluginKey('handleClickLink'),
                    props: {
                        handleClick: (view, pos, event) => {
                            const link = event.target
                            if(link && link.href && link.target!=='_parent'){
                                window.open(link.href, link.target)
                                return true
                            }
                            return false
                        }
                    }
                })
            )
        }

        if(this.options.linkOnPaste){
            plugins.push(
                new Plugin({
                    key: new PluginKey('handlePasteLink'),
                    props: {
                        handlePaste: (view, event, slice) => {
                            if(view?.state?.Selection?.empty){
                                return false
                            }

                            let textContent = slice.content.content[0].textContent
                            if (!textContent || !textContent.match(pasteRegexExact)) {
                                return false
                            }

                            this.editor.commands.setMark(this.type, {
                                href: textContent
                            })

                            return true
                        }
                    }
                })
            )
        }

        return plugins
    }
})