import {Mark, mergeAttributes} from '@tiptap/core'

export default Mark.create({
    name: 'superscript',

    defaultOptions: {
        HTMLAttributes: {}
    },

    parseHTML(){
        return [
            {
                tag: 'p>sup'
            },
            {
                style: 'vertical-align=super'
            }
        ]
    },

    renderHTML({HTMLAttributes}){
        return ['sup', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands(){
        return {
            setSuperscript: () => ({commands}) => {
                return commands.unsetMark('superscript')
            },
            toggleSuperscript: () => ({commands}) => {
                return commands.toggleMark('superscript')
            },
            unsetSuperscript: () => ({commands}) => {
                return commands.unsetMark('superscript')
            }
        }
    },

    addKeyboardShortcuts(){
        return {
            'Mod-ArrowUp': () => this.editor.commands.toggleSuperscript()
        }
    }
})