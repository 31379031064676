import {Node, mergeAttributes} from '@tiptap/core'

export default Node.create({
    name: 'figure',

    defaultOptions: {
        HTMLAttributes: {}
    },

    group: 'block',

    content: 'block+',

    draggable: true,

    parseHTML(){
        return [
            {
                tag: 'figure',
                getAttrs(dom) {
                    const img = dom.querySelector('img');
                    return {
                      src: img ? img.src : null,
                    };
                  }
            }
        ]
    },

    renderHTML({HTMLAttributes}){
        return ['figure', ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)], ['figcaption', 0]]
    },

    addAttributes(){
        return {
            src: {
                default: null
            }
        }
    },

    addCommands(){
        return {
            setFigure: attributes => ({commands}) => {
                return commands.wrapIn('figure', attributes)
            }
        }
    },

    addNodeView(){
        return ({
            HTMLAttributes
        }) => {
            const figure = document.createElement('figure')
            const image = document.createElement('img')
            const caption = document.createElement('figcaption')

            image.src = HTMLAttributes.src
            caption.contentEditable = true
            figure.append(image, caption)
        
            return {
                dom: figure,
                contentDOM: caption
            }
        }
    }
})