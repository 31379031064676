import {Node, mergeAttributes} from '@tiptap/core'

export default Node.create({
    name: 'orderedList',

    defaultOptions: {
        HTMLAttributes: {}
    },

    group: 'block list',

    content: 'listItem+',

    parseHTML(){
        return [
            {
                tag: 'ol'
            }
        ]
    },

    renderHTML({HTMLAttributes}){
        return ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands(){
        return {
            toggleOrderedList: () => ({commands}) => {
                return commands.toggleList('orderedList', 'listItem')
            }
        }
    },

    addKeyboardShortcuts() {
        return {
          'Mod-Shift-7': () => this.editor.commands.toggleOrderedList(),
        }
    },
})