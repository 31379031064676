<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Edit Tag</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">
                  <v-text-field
                    class="mt-2"
                    v-model.trim="tag.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Tag from '@/api/tag'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      tag: {
        name: ""
      },
      rules: {
        name: [v => !!v || "Name is required"]
      }
    };
  },
  mounted(){
      this.spinner(true)
      this.tag.name = this.vg_editObj.name
      this.spinner(false)
  },
  computed: {
      ...mapGetters({
          vg_editObj: 'tag/editObj'
      })
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Tag.update(this.vg_editObj.id, this.tag)
          this.snackbar({status: true, message: data.message, color:'success'})
          this.spinner(false)
          this.$router.push({name: 'Tag'})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color: 'error'})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Tag" });
    }
  }
};
</script>