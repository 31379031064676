<template>
    <div class="v-input">
        <div class="v-input__control">
            <label class="v-label v-label--active theme--light mb-2" v-text="label"></label>
            <div class="v-input__slot">
                <div class="v-text-field__slot" :style="'text-align: center; border: solid thin #ccc; padding:5px; min-width:150px; min-height:150px; width:'+width+'px; height'+height+'px;'">
                    <v-img slot="activator" contain v-if="value" :src="value" min-height="140" min-width="140" :max-width="width" :max-height="height" />
                    <v-icon dense class="ma-0 pa-0" size="160" v-else>mdi-image</v-icon>
                    <div width="100%">
                    <v-btn small v-if="value" class="mt-1 primary" @click="openFileManager">
                        Update
                    </v-btn>
                    <v-btn small v-else class="mt-1 primary" @click="openFileManager">
                        Select
                    </v-btn>
                    <v-btn small class="mt-1 mr-3 primary float-right" :disabled="!value" @click.prevent="clearSelection">
                        Clear
                    </v-btn>
                    <v-text-field v-model="value" class="d-none" :rules="rules"></v-text-field>
                    </div>
                </div>
            </div>
            <div class="v-text-field__details"></div> 
            <manager :openManager="openManager" :multiple="multiple" @closeManger="closeManager" @selected="selectedFile" />
        </div>
    </div>
</template>

<script>
import manager from './main/manager'
export default {
    props: ['rules', 'label', 'value', 'width', 'height', 'allowed', 'multiple', 'crop'],
    components: {
        manager
    },
    data(){
        return {
            openManager: false
        }
    },
    methods: {
        openFileManager(){
            this.openManager = true
        },
        closeManager(){
            this.openManager = false
        },
        selectedFile(files){
            // if(!Array.isArray(files)){
            //     this.image = files
            // }
            this.$emit('input', files)
            this.openManager = false

        },
        clearSelection(){
            this.$emit('input', '')
        }
    }
}
</script>
