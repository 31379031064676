import Article from '@/views/article';
import ArticleCreate from '@/views/article/create'
import ArticleEdit from '@/views/article/edit'
import ArticleTrash from '@/views/article/trash'

export default [
    {
        name: 'Article',
        path: '/article',
        component: Article,
        meta: {
            nav: {
                addToNav: true,
                navText: 'Article',
                header: 'Article',
                navIcon: 'mdi-post',
            }
        },
    },
    {
        name: 'ArticleCreate',
        path: '/article/create',
        component: ArticleCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Article > Create',
                navIcon: '',
            }
        }
    },
    {
        name: 'ArticleEdit',
        path: '/article/edit/:slug',
        component: ArticleEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Article > Edit',
                navIcon: '',
            }
        }
    },
    {
        name: 'ArticleTrash',
        path: '/article/trash',
        component: ArticleTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Article > Trash',
                navIcon: '',
            }
        }
    }
]