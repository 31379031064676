<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Edit Post</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="10" offset-md="1">

                  <v-select
                    class="mt-2"
                    :items="status_items"
                    v-model="form.status"
                    :rules="rules.status"
                    label="Status*"
                  ></v-select>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.title"
                    :rules="rules.title"
                    hint="Please enter title"
                    label="Title*"
                    disabled
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.slug"
                    :rules="rules.slug"
                    hint="Please enter slug"
                    label="Slug*"
                    disabled
                  ></v-text-field>

                  <editor 
                    class="main-editor pb-9" 
                    v-model="form.description" 
                    label="Description" 
                    :rules="rules.description" 
                    :loading="loadingEditor"
                  />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Post from "@/api/post"
import ObjectHelper from "@/helper/object"
import Editor from '@/components/editor'
import {mapGetters, mapMutations} from "vuex"

export default {
  components: {
    Editor
  },
  data() {
    return {
      status_items:[{text: 'Assigned', value: 'assigned'}, {text: 'Progress', value: 'progress'}, {text:'Completed', value:'completed'},{text: 'Published', value: 'published'}],
      valid: null,
      form: {
        status: null,
        title: null,
        slug: null,
        description: null,
      },
      rules: {
        status: [
          v => !!v || "Status is required",
        ],
        title: [
          v => !!v || "Title is required",
        ],
        slug: [
          v => !!v || "Slug is required",
        ],
        description: [
        ],
      },
      loadingEditor: true
    };
  },
  async mounted(){
    this.spinner(true)
    await this.getPost()
    this.loadingEditor = false
    this.spinner(false)
  },
  watch: {
    'form.title'(value){
      this.form.slug = value.trim().replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "-").replace(/-{2,}/g, '-').toLowerCase()
    }
  },
  computed: {
      ...mapGetters({
          vg_editObj: "post/editObj"
      })
  },
  methods: {
    async getPost(){
      try{
        let {data} = await Post.findBySlug(this.$route.params.slug)
        if(data.payload){
          this.vm_editObj(data.payload)

          this.form.status = this.vg_editObj.status
          this.form.title = this.vg_editObj.title
          this.form.slug = this.vg_editObj.slug
          this.form.description = this.vg_editObj.description
        }
      }catch(err){
        console.log(err)
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Post.update(this.vg_editObj.id, ObjectHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Post"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Post" });
    },
    ...mapMutations({
      vm_editObj: 'post/editObj'
    })
  }
};
</script>