<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Edit Reference</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-alert v-if="errors.length>0" dark color="red lighten-2">
                <div v-for="error in errors" :key="error">{{error}}</div>
              </v-alert>
              <v-row>
                <v-col cols="10" offset="1" md="10" offset-md="1">
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="form.type"
                        :items="types"
                        :rules="rules.type"
                        label="Type*"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-text-field
                    class="mt-2"
                    v-model="form.title"
                    :rules="rules.title"
                    hint="Please enter title"
                    label="Title*"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="form.authors"
                    :rules="rules.authors"
                    hint="LastName, FirstName; LastName, FirstName"
                    label="Authors*"
                  ></v-text-field>

                  <v-text-field
                    v-if="form.type==='Journal'"
                    class="mt-2"
                    v-model="form.journal"
                    label="Journal"
                  ></v-text-field>

                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-2"
                        v-model.number="form.volume"
                        label="Volume"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-if="form.type==='Journal'"
                        class="mt-2"
                        v-model="form.issue"
                        label="Issue"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="form.type==='Journal'">
                    <v-col cols="6">
                      <v-select
                        v-model="form.identifier_type"
                        :items="identifiers"
                        :rules="rules.identifier"
                        label="Identifier*"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-2"
                        v-model.number="form.identifier"
                        label="Identifier Number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-text-field
                    v-if="form.type==='Book'"
                    class="mt-2"
                    v-model="form.edition"
                    label="Edition"
                  ></v-text-field>

                  <v-text-field
                    v-if="form.type==='Book'"
                    class="mt-2"
                    v-model="form.editors"
                    label="Editors"
                  ></v-text-field>

                  <v-text-field
                    v-if="form.type==='Book'"
                    class="mt-2"
                    v-model="form.city"
                    label="City"
                  ></v-text-field>

                  <v-text-field
                    v-if="form.type==='Book'"
                    class="mt-2"
                    v-model="form.publisher"
                    label="Publisher"
                  ></v-text-field>

                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-2"
                        v-model.number="form.start_page"
                        label="Start Page"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        class="mt-2"
                        v-model.number="form.end_page"
                        label="End Page"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        class="mt-2"
                        v-model.number="form.year"
                        label="Year"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        class="mt-2"
                        v-model.number="form.month"
                        label="Month"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        class="mt-2"
                        v-model.number="form.day"
                        label="Day"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-textarea
                    v-model="form.abstract"
                    label="Abstract"
                  ></v-textarea>

                  <v-text-field
                    class="mt-2"
                    v-model="form.url"
                    label="Url"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ReferenceApi from '@/api/reference'
import StringHelper from '@/helper/string'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      errors: [],
      valid: false,
      identifiers: [
        {text: 'PMID', value: 'PMID'},
        {text: 'DOI', value: 'DOI'},
      ],
      types: [
        {text: 'Journal', value: 'Journal'},
        {text: 'Book', value: 'Book'},
      ],
      form: {
        identifier_type: "",
        identifier: "",
        type: "",
        title: "",
        authors: "",
        journal: "",
        volume: "",
        issue: "",
        edition: "",
        editors: "",
        city: "",
        publisher: "",
        start_page: "",
        end_page: "",
        year: "",
        month: "",
        day: "",
        url: ""
      },
      rules: {
        identifier: [v => !!v || "Identifier is required"],
        type: [v => !!v || "Type is required"],
        title: [v => !!v || "Title is required"],
        authors: [v => !!v || "Authors is required"],
        abstract: [v => !!v || "Abstract is required"]
      }
    };
  },
  mounted(){
      this.spinner(true)
      this.form.identifier = this.vg_editObj.identifier
      this.form.identifier_type = this.vg_editObj.identifier_type
      this.form.type = this.vg_editObj.type
      this.form.title = this.vg_editObj.title
      this.form.authors = this.vg_editObj.authors
      this.form.journal = this.vg_editObj.journal
      this.form.volume = this.vg_editObj.volume
      this.form.issue = this.vg_editObj.issue
      this.form.edition = this.vg_editObj.edition
      this.form.editors = this.vg_editObj.editors
      this.form.city = this.vg_editObj.city
      this.form.publisher = this.vg_editObj.publisher
      this.form.start_page = this.vg_editObj.start_page
      this.form.end_page = this.vg_editObj.end_page
      this.form.year = this.vg_editObj.year
      this.form.month = this.vg_editObj.month
      this.form.day = this.vg_editObj.day
      this.form.abstract = this.vg_editObj.abstract
      this.form.url = this.vg_editObj.url
      this.spinner(false)
  },
  computed: {
      ...mapGetters({
          vg_editObj: 'reference/editObj'
      })
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          if(this.form.type==='Journal'){
            this.form.edition = null
            this.form.editors = null
            this.form.publisher = null
            this.form.city = ''
          }else if(this.form.type==='Book'){
            this.form.journal = null
            this.form.issue = null
          }
          let {data} = await ReferenceApi.update(this.vg_editObj.id, StringHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:'success'})
          this.spinner(false)
          this.$router.push({name: 'Reference'})
        }catch(err){
          this.errors = err.response.data.payload
          this.snackbar({status: true, message:err.response.data.message, color: 'error'})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Reference" });
    }
  }
};
</script>