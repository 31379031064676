<template>
    <div class="v-input">
        <div class="v-input__control">
            <label class="v-label v-label--active theme--light mb-2" v-text="label"></label>
            <v-combobox
                v-model="selected"
                :items="items"
                :search-input.sync="search"
                label="Type tags"
                item-value="id"
                item-text="name"
                hide-selected
                multiple
                small-chips
                solo >
                    <template v-slot:item="{ item }">
                        <v-chip
                            :color="`lighten-3`"
                            dark
                            label
                            small
                            >
                            {{ item.name }}
                        </v-chip>
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip 
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            :color="`lighten-3`"
                            :input-value="selected"
                            label
                            small
                            >
                            <span class="pr-2">
                                {{ item.name }}
                            </span>
                            <v-icon
                                small
                                @click="parent.selectItem(item)"
                            >
                                $delete
                            </v-icon>
                        </v-chip>
                    </template>
            </v-combobox>
            <v-text-field class="d-none" v-model="selected" :rules="rules" />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import TagApi from '@/api/tag'
export default {
    props: ['value', 'label', 'rules'],
    data(){
        return {
            selected: [],
            search: '',
            items: []
        }
    },
    watch: {
        value(values){
            this.selected = values
        },
        selected(values){
            this.$emit('input', values)
        },
        search: _.debounce(async function(val){
            if(val){
                await this.getTags(val)
            }
        }, 900)
    },
    mounted(){
        this.selected = this.value
    },
    methods: {
        async getTags(tagName){
            let {data} = await TagApi.findTag(tagName)
            this.items =data.payload
        }
    }
}
</script>