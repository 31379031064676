<template>
    <v-dialog v-model="openLink" persistent max-width="450">
        <v-card>
            <v-toolbar elevation="0" dark class="secondary">
                <v-toolbar-title>Add Link</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeLink">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <v-form ref="form" v-model="valid" @submit.prevent="addLink">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete :rules="linkRule" v-model="form.url" :items="articles" :loading="searchingArticle" :search-input.sync="search" item-text="title" item-value="url" label="URL" placeholder="Type title or https://" auto-select-first no-data-text></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-switch v-model="form.openInNewTab" label="Open in new tab" hide-details></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <v-switch v-model="form.noFollow" label="No Follow" hide-details></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-switch v-model="form.noOpener" label="No Opener" hide-details></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <v-switch v-model="form.noReferrer" label="No Referrer" hide-details></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn :disabled="!valid" type="submit" class="primary" block>Insert Link</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import ArticleAPI from '@/api/article'

export default {
    props: ['openLink', 'linkAttrs'],
    data(){
        return {
            linkRule: [
                v => !!v || 'Required'
            ],
            valid: false,
            form: {
                url: '',
                openInNewTab: false,
                noFollow: false,
                noOpener: false,
                noReferrer: false
            },
            search: '',
            articles: [],
            searchingArticle: false
        }
    },
    watch: {
        openLink(val){
            if(val){                
                this.form.url = this.linkAttrs.href
                this.form.openInNewTab = this.linkAttrs.target==='_blank'?true:false
                this.form.noFollow = (this.linkAttrs.rel && this.linkAttrs.rel.includes('nofollow'))?true:false
                this.form.noOpener = (this.linkAttrs.rel && this.linkAttrs.rel.includes('noopener'))?true:false
                this.form.noReferrer = (this.linkAttrs.rel && this.linkAttrs.rel.includes('noreferrer'))?true:false

                if(this.form.url && this.form.url.indexOf('studymicrobio')>=0){
                    this.searchArticle(this.form.url.split('/').pop())
                }else if(this.form.url){
                    this.articles = [{title: this.form.url, link: this.form.url}]
                }
            }
        },
        search(word){
            if((word!==null && word!==' ' && 'http:'.indexOf(word)<0 && word.indexOf('http:')<0 && 'https:'.indexOf(word)<0 && word.indexOf('https:')<0)){
                this.searchArticle(word)
            }else if(word!==null && word!==' '){
                this.articles = [{title: word, link: word}]
            }
        }
    },
    methods: {
        closeLink(){
            this.$emit('closeLink')
        },
        addLink(){
            if(this.$refs.form.validate()){
                this.linkPop = false
                this.$emit('addLink', this.form)
            }
        },
        async searchArticle(word){
            if(word){
                this.searchingArticle = true
                try{
                    let {data} = await ArticleAPI.listLinkAndTitle(word)
                    this.articles = data.payload
                    this.searchingArticle = false
                }catch(err){
                    this.searchingArticle = false
                }
            }
        }
    }
}
</script>
